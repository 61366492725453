// eslint-disable-next-line no-restricted-syntax
import { DialogModule } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';

import { CosBottomSheetContainerComponent } from './bottom-sheet-container';

@NgModule({
  imports: [PortalModule, DialogModule],
  exports: [CosBottomSheetContainerComponent],
  declarations: [CosBottomSheetContainerComponent],
})
export class CosBottomSheetModule {}
