import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import {
  CustomerPortalDistributorState,
  CustomerPortalPresentationProductsPrintPreviewState,
  CustomerPortalPresentationProductsState,
  CustomerPortalPresentationState,
  PresentationCartState,
} from './states';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      PresentationCartState,
      CustomerPortalDistributorState,
      CustomerPortalPresentationState,
      CustomerPortalPresentationProductsState,
      CustomerPortalPresentationProductsPrintPreviewState,
    ]),
  ],
})
export class CustomerPortalPresentationsDataAccessPresentationsModule {}
