import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { TeleportOutletDirective } from '@ngneat/overview';
import { Store } from '@ngxs/store';
import { catchError, EMPTY, map } from 'rxjs';

import { collectRouteParams, RouteSnapshotService } from '@cosmos/router';
import { assertDefined } from '@cosmos/util-common';
import { CosmosUtilTranslationsModule } from '@cosmos/util-translations';
import { CustomerPortalAuthQueries } from '@customer-portal/auth/data-access-auth';
import { CustomerPortalFooterComponent } from '@customer-portal/common/feature-footer';
import { CustomerPortalHeaderComponent } from '@customer-portal/common/feature-header';
import {
  CustomerPortalProjectActions,
  CustomerPortalProjectQueries,
} from '@customer-portal/projects/data-access/store';

@Component({
  selector: 'customer-portal-projects-host',
  templateUrl: './projects-host.component.html',
  styleUrls: ['./projects-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    TeleportOutletDirective,

    CustomerPortalHeaderComponent,
    CustomerPortalFooterComponent,
    CosmosUtilTranslationsModule,
  ],
  host: {
    '[class.preview-mode]': 'userIsDistributor',
  },
})
export class CustomerPortalProjectsHostComponent implements OnInit {
  routesParam?: {
    projectId: string;
    orderId: string;
    invoiceId: string;
    quoteId: string;
  };

  readonly userIsDistributor = this._store.selectSnapshot(
    CustomerPortalAuthQueries.getUserIsDistributor
  );

  private readonly _document = inject(DOCUMENT);

  // we can't hide the header/footer using media queries since there are pages where we have to hide them on the screen
  vm = toSignal(
    inject(RouteSnapshotService).snapshot$.pipe(
      map((snapshot) => ({
        showHeader: !snapshot?.data?.['hideHeader'],
        showFooter: !snapshot?.data?.['hideFooter'],
      }))
    ),
    {
      initialValue: { showHeader: true, showFooter: true },
    }
  );

  constructor(
    private readonly _router: Router,
    private readonly _store: Store
  ) {}

  get previewingPresentation(): boolean {
    return !(
      this.routesParam?.orderId ||
      this.routesParam?.invoiceId ||
      this.routesParam?.quoteId
    );
  }

  ngOnInit(): void {
    const { projectId, orderId, invoiceId, quoteId } = collectRouteParams(
      this._router
    );
    this.routesParam = { projectId, orderId, invoiceId, quoteId };

    !global_isRealProduction &&
      assertDefined(
        projectId,
        'CustomerPortalProjectsHostComponent: projectId should be defined on route params'
      );

    this._store
      .dispatch(new CustomerPortalProjectActions.GetProject(projectId))
      .pipe(catchError(() => EMPTY))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        const project = this._store.selectSnapshot(
          CustomerPortalProjectQueries.getProject
        )!;

        if (global_isBrowser && project.Customer.IconImageUrl) {
          const link =
            this._document.querySelector<HTMLLinkElement>('#portal-favicon')!;
          link.href = project.Customer.IconImageUrl;
        }
      });
  }
}
