import type { INavigationItem, MenuItem } from '@cosmos/types-layout';

/**
 * Returns an array of all children items of the given items.
 */
export function getAllNestedChildrenItems(items: MenuItem[]): MenuItem[] {
  return items.flatMap(getChildrenItems);
}

/**
 * Returns an array of all children items of the given item.
 */
export function getChildrenItems(item: MenuItem): INavigationItem[] {
  if (item.children) {
    return item.children.flatMap((it) => [it, ...getChildrenItems(it)]);
  }

  return [];
}
