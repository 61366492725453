import type { FeatureFlagsOptions } from '@cosmos/feature-flags';

import { releaseFlags } from './release-features';

export const featureFlagsToolbarOptions: FeatureFlagsOptions = {
  development: {
    title: 'Development',
    color: '#c23f22',
    flags: {
      payment_providers: true,
      portal_ssr: true,
    },
  },
  release: {
    title: 'Release',
    color: '#09755d',
    flags: releaseFlags,
  },
};
