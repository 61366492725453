import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CosButtonModule } from '@cosmos/components/button';
import { CosInputModule } from '@cosmos/components/input';
import { CosMenuModule } from '@cosmos/components/menu';
import { CosSlideToggleModule } from '@cosmos/components/toggle';
import { CosTooltipModule } from '@cosmos/components/tooltip';
import { FeatureFlagsFilterPipe } from '@cosmos/feature-flags';
import { CosBottomSheetModule } from '@cosmos/ui-bottom-sheet';
import { CosActionDelegateOnClickDirective } from '@cosmos/ui-delegated-action-processing';
import { CapabilityFilterPipe, RoleFilterPipe } from '@cosmos/ui-navigation';
import { ResizeObserverDirective } from '@cosmos/ui-resize-observer-directive';
import { CosmosUtilTranslationsModule } from '@cosmos/util-translations';

import { CosGlobalMenuComponent } from './global-menu.component';

@NgModule({
  imports: [
    CommonModule,
    FeatureFlagsFilterPipe,
    CosBottomSheetModule,
    CosMenuModule,
    CosButtonModule,
    CosInputModule,
    CosSlideToggleModule,
    RouterModule,
    FormsModule,
    PortalModule,
    ReactiveFormsModule,
    ResizeObserverDirective,
    CosTooltipModule,
    CosmosUtilTranslationsModule,
    CosActionDelegateOnClickDirective,
    RoleFilterPipe,
    CapabilityFilterPipe,
  ],
  exports: [CosGlobalMenuComponent],
  declarations: [CosGlobalMenuComponent],
})
export class CosGlobalHeaderModule {}
