import type { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JwtInterceptor } from '@asi/auth/data-access-auth';

@Injectable()
export class AuthInterceptor extends JwtInterceptor {
  override interceptToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
