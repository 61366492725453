import { NavigationItem } from './navigation';

export class MenuItem extends NavigationItem {}

export type MenuItemsKeyValue = {
  [key: string]: (overrides?: Partial<MenuItemOverrideSettings>) => MenuItem;
};

// TODO(FP): we shouldn't have the featureFlags here. It is used in navItemsMobile though. We should remove this property when we'll work in this ticket ENCORE-143831
export type MenuItemOverrideSettings = Pick<
  MenuItem,
  | 'icon'
  | 'hideTitle'
  | 'featureFlags'
  | 'menuSettings'
  | 'url'
  | 'menuFactory'
  | 'title'
  | 'children'
>;

// Note: this array of types is being used by the menu.items.config.ts file to guard the overridden types of the menu items
export const allowedPropertiesToOverride: (keyof MenuItemOverrideSettings)[] = [
  'icon',
  'hideTitle',
  'featureFlags',
  'menuSettings',
  'url',
  'menuFactory',
  'title',
  'children',
];

/**
 * This type defines a menu item with overrides.
 *
 * e.g.: { menuType: 'collections', overrides: { icon: undefined } }
 */
export type MenuWithOverrides = {
  menuType: string;
  overrides: Partial<MenuItemOverrideSettings>;
};

/**
 * This type is used to define the menu items configuration for the global header.
 */
export type MenuTypes = string | MenuItem | MenuWithOverrides;

export type MenuItemsConfig = MenuTypes[];

export function isMenuWithOverrides(
  item: MenuTypes
): item is MenuWithOverrides {
  return (
    Object.prototype.hasOwnProperty.call(item, 'menuType') &&
    Object.prototype.hasOwnProperty.call(item, 'overrides')
  );
}

export function isMenuWithComponentSettings(item: MenuTypes): item is MenuItem {
  return Object.prototype.hasOwnProperty.call(item, 'menuSettings');
}
