import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'customer-portal-header-loader',
  templateUrl: './header-loader.component.html',
  styleUrls: ['./header-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
})
export class CustomerPortalHeaderLoaderComponent {}
