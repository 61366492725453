import type { HashMap } from '@ngneat/transloco/lib/types';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import type { ErrorsMap } from '@cosmos/components/form-field';
import {
  CosmosTranslocoService,
  LanguageScope,
} from '@cosmos/util-translations';

const getKey = (
  translocoService: CosmosTranslocoService,
  key: string,
  params?: HashMap
) =>
  translocoService.translate(
    `cosmos.components.form-field.customer-portal-errors.${key}`,
    { ...params }
  );

export const formFieldErrorsConfig = (
  translocoService: CosmosTranslocoService
): Observable<ErrorsMap> => {
  return translocoService.getLangChanges$([LanguageScope.Cosmos]).pipe(
    map(() => {
      return {
        email: getKey(translocoService, 'email'),
        max: ({ max, actualValue, label }) =>
          label
            ? getKey(translocoService, 'max-with-label', { label, max })
            : getKey(translocoService, 'max-with-label', { max }),
        maxlength: ({ requiredLength, actualLength, label }) =>
          label
            ? getKey(translocoService, 'max-length-with-label', {
                label,
                requiredLength,
              })
            : getKey(translocoService, 'max-length-no-label', {
                requiredLength,
              }),
        minlength: ({ requiredLength, actualLength, label }) =>
          label
            ? getKey(translocoService, 'min-length-with-label', {
                label,
                requiredLength,
              })
            : getKey(translocoService, 'min-length-no-label', {
                requiredLength,
              }),
        min: ({ min, actualValue, label }) =>
          label
            ? getKey(translocoService, 'min-with-label', { label, min })
            : getKey(translocoService, 'min-no-label', { min }),
        required: getKey(translocoService, 'required'), // 'This field is required.',
        serverError: (error) => error,
      };
    })
  );
};
