import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  importProvidersFrom,
  makeEnvironmentProviders,
  type EnvironmentProviders,
} from '@angular/core';
import {
  NgxsRouterPluginModule,
  type NavigationActionTiming,
} from '@ngxs/router-plugin';
import {
  LOCAL_STORAGE_ENGINE,
  NgxsStoragePluginModule,
  SESSION_STORAGE_ENGINE,
} from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { AsiAuthService } from '@asi/auth/data-access-auth';
import { CosDialogModule } from '@cosmos/components/dialog';
import { CosFormFieldModule } from '@cosmos/components/form-field';
import { AppSettingsModule } from '@cosmos/data-access-app-settings';
import { provideMeta } from '@cosmos/meta';
import { provideNgEventPlugins } from '@cosmos/ng-event-plugins';
import { CosNotificationModule } from '@cosmos/ui-notification';
import { provideToast } from '@cosmos/ui-notification-toast';
import { COS_DATE_LOCALE } from '@cosmos/util-datetime';
import { provideWebDownload } from '@cosmos/util-download';
import {
  CustomerPortalAuthDataAccessAuthModule,
  ɵCustomerPortalAuthState,
} from '@customer-portal/auth/data-access-auth';
import {
  CustomerPortalCollectionAuthDataAccessAuthModule,
  CustomerPortalCollectionAuthState,
} from '@customer-portal/collections/data-access-auth';
import { CustomerPortalCollectionsDataAccessCollectionsModule } from '@customer-portal/collections/data-access-collections';
import { CustomerPortalPresentationsDataAccessPresentationsModule } from '@customer-portal/presentations/data-access-presentations';
import { CustomerPortalProjectsDataAccessStoreModule } from '@customer-portal/projects/data-access/store';
import { EspLookupTypesModule } from '@esp/lookup/data-access-lookup';
import { provideCustomRouterStateSerializer } from '@esp/router';
import { PaymentProvidersSettingsState } from '@esp/settings/data-access-settings';

import { environment } from '../../environments/environment';

import { formFieldErrorsConfig } from './configs';
import { AuthInterceptor } from './interceptors';
import { CustomerPortalAuthService } from './services';

const NavigationActionTiming__PostActivation: NavigationActionTiming.PostActivation = 2;
export function makeCoreEnvironmentProviders(): EnvironmentProviders[] {
  const imports = [
    CosDialogModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: environment.production,
      },
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    NgxsRouterPluginModule.forRoot({
      navigationActionTiming: NavigationActionTiming__PostActivation,
    }),
    NgxsStoragePluginModule.forRoot({
      namespace: 'portal',
      keys: [
        {
          key: ɵCustomerPortalAuthState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: CustomerPortalCollectionAuthState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: 'appSettings',
          engine: LOCAL_STORAGE_ENGINE,
        },
      ],
    }),
    AppSettingsModule,
    CosNotificationModule,
    CosFormFieldModule.forRoot(formFieldErrorsConfig),
    environment.imports,
    EspLookupTypesModule,
    CustomerPortalAuthDataAccessAuthModule,
    CustomerPortalCollectionAuthDataAccessAuthModule,
    CustomerPortalPresentationsDataAccessPresentationsModule,
    CustomerPortalProjectsDataAccessStoreModule,
    CustomerPortalCollectionsDataAccessCollectionsModule,
    NgxsModule.forFeature([PaymentProvidersSettingsState]),
  ];

  const providers = [
    {
      provide: AsiAuthService,
      useExisting: CustomerPortalAuthService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideCustomRouterStateSerializer(),
    { provide: COS_DATE_LOCALE, useValue: 'en-US' },
    provideWebDownload(),
    ...(environment.providers || []),
    provideNgEventPlugins(),
    provideMeta({
      applicationName: 'Portal',
      pageTitleSeparator: ' - ',
      defaults: {
        title: 'Portal',
      },
    }),
    provideToast(),
  ];

  return [importProvidersFrom(imports), makeEnvironmentProviders(providers)];
}
