import { inject, Injector } from '@angular/core';

import type {
  ActionDelegateFactory,
  ActionDelegateService,
} from '@cosmos/types-common';

export const injectActionExecutor = () => {
  const injector = inject(Injector);

  return async (factory: ActionDelegateFactory | null) => {
    const actionPromise = factory?.();

    if (actionPromise && actionPromise instanceof Promise) {
      const actionToInvoke = await actionPromise;

      injector.get<ActionDelegateService>(actionToInvoke)?.init();
    }
  };
};
