import {
  APP_INITIALIZER,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';

import { CosToastService } from './toast.service';

export function provideToast() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        inject(CosToastService);
        return () => void 0;
      },
    },
  ]);
}
