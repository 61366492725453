import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import {
  CustomerPortalProjectDetailsState,
  CustomerPortalProjectState,
} from './states';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      CustomerPortalProjectState,
      CustomerPortalProjectDetailsState,
    ]),
  ],
})
export class CustomerPortalProjectsDataAccessStoreModule {}
