import { EMPTY, type Observable } from 'rxjs';

import { DataBus } from './base-data-bus';
import type { DataBusConfig, DataBusMessage } from './types';

export interface HostDataBusConfig extends DataBusConfig {
  /**
   * Iframe element to emit to.
   * You can provide a function that will be resolved at the time message is emitted
   */
  targetFrame: HTMLIFrameElement | (() => HTMLIFrameElement);
}

export class IframeDataBus<
  T extends DataBusMessage = DataBusMessage,
> extends DataBus<T> {
  protected _getTarget(): Window | null {
    // for the iframe the parent window is always a target
    const parent = this.config.currentWindow?.parent;
    return parent && parent !== this.config.currentWindow ? parent : null;
  }
  protected override _getOnMessage$(): Observable<DataBusMessage<unknown>> {
    if (!this._getTarget()) {
      if (ngDevMode && global_isBrowser) {
        console.warn(
          'Could not resolve the target window, skipping subscription'
        );
      }
      // if there's no target, do not instantiate the subscription
      return EMPTY;
    }
    return super._getOnMessage$();
  }
}

export class HostDataBus<
  T extends DataBusMessage = DataBusMessage,
> extends DataBus<T> {
  constructor(protected override config: HostDataBusConfig) {
    super(config);
  }

  protected _getTarget(): Window | null {
    let frame = this.config.targetFrame!;
    if (typeof frame === 'function') {
      frame = frame();
    }
    if (!this.config.currentWindow.document.contains(frame)) {
      return null;
    }
    if (!(frame instanceof HTMLIFrameElement)) {
      return null;
    }
    return frame.contentWindow;
  }
}
