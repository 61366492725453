import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, EMPTY } from 'rxjs';

import { getParamsObject } from '@cosmos/core';
import {
  CustomerPortalDistributorActions,
  CustomerPortalPresentationActions,
} from '@customer-portal/presentations/data-access-presentations';
import {
  CustomerPortalProjectDetailsActions,
  CustomerPortalProjectDetailsQueries,
} from '@customer-portal/projects/data-access/store';

@Injectable({ providedIn: 'root' })
export class CustomerPortalProjectDetailsResolver implements Resolve<void> {
  constructor(private readonly _store: Store) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { projectId } = getParamsObject(route.paramMap, {
      required: ['projectId'],
    })!;

    // `settings` query parameter is set only when distributors preview the customer portal page.
    // They're able to do that when clicking the share button and then clicking the preview presentation.
    // This will redirect distributors with `token` and `settings` query parameters.
    const settings = route.queryParamMap.get('settings');

    // Note: do not return the result of this observable since it shouldn't
    // block the UI from loading; this should act as fire & forget; hereby,
    // we're able to show skeleton loaders.
    this._store
      .dispatch(
        new CustomerPortalProjectDetailsActions.GetProjectDetails(projectId)
      )
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        // We first need to get the project details since it has the `PresentationId`.
        const presentationId = this._store.selectSnapshot(
          CustomerPortalProjectDetailsQueries.getPresentationId
        );

        this._store.dispatch([
          new CustomerPortalPresentationActions.GetPresentation(
            presentationId,
            /* shouldLoadQuote */ true,
            settings
          ),
          new CustomerPortalDistributorActions.GetDistributor(presentationId),
          new CustomerPortalDistributorActions.GetDesign(presentationId),
        ]);
      });
  }
}
