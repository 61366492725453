import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  input,
  NgZone,
  signal,
  ViewEncapsulation,
  type Type,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { filter, from } from 'rxjs';

import {
  isMenuWithComponentSettings,
  type INavigationItem,
  type MenuItem,
} from '@cosmos/types-layout';
import {
  CosBottomSheet,
  type CosBottomSheetRef,
} from '@cosmos/ui-bottom-sheet';
import { injectActionExecutor } from '@cosmos/ui-delegated-action-processing';
import { getChildrenItems } from '@cosmos/util-navigation-items';

@Component({
  selector: 'cos-global-menu',
  templateUrl: './global-menu.component.html',
  styleUrls: ['./global-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'cos-global-menu',
  },
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CosGlobalMenuComponent {
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _bottomSheet = inject(CosBottomSheet);
  private readonly _router = inject(Router);
  private readonly _actionExecutor = injectActionExecutor();

  private _bottomSheetRef: CosBottomSheetRef | null = null;
  readonly bottomSheetOpen = signal(false);

  readonly ariaLabel = input('');
  readonly navItemsDesktop = input<MenuItem[]>([]);
  readonly navItemsMobile = input<MenuItem[]>([]);

  readonly bottomSheetLogoSrc = input('');
  readonly theme = input<null | 'light'>(null);

  private readonly _destroyRef = inject(DestroyRef);

  private readonly _document = inject(DOCUMENT);

  constructor() {
    this._closeBottomSheetOnNavigation();
  }

  // Note: this method handles the opening of the bottom-sheet menu.
  // Bottom-sheet menu is the menu that appears when the user clicks on the "more" button in the mobile navigation.
  // Furthermore, open-sheet menu are the "notifications | community-links | help-center"  menus.
  openBottomSheet(navItem: INavigationItem): void {
    if (this.bottomSheetOpen()) {
      this._bottomSheetRef?.dismiss();
      return;
    }

    this._document.body.classList.add('bottom-sheet-open');

    const componentPromise = navItem.component?.();

    if (!componentPromise || !(componentPromise instanceof Promise)) {
      if (ngDevMode) {
        throw new Error(
          'The `component` property should return an async function, that lazy loads the component to show.'
        );
      }

      return;
    }

    from(componentPromise)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((component) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this._bottomSheetRef = this._bottomSheet.open(component as Type<any>);

        // We are providing the children menu items as an input to the bottom-sheet component,
        // so that it can render the children menu items.
        if (navItem.children) {
          this._bottomSheetRef.componentRef?.setInput('menuSettings', {
            children: navItem.children,
          });
        }

        // The menuSettings is used to pass dynamic settings to the menu component.
        // If it is set, we are providing it as an input to the bottom-sheet component.
        if (isMenuWithComponentSettings(navItem) && navItem.menuSettings) {
          this._bottomSheetRef.componentRef?.setInput(
            'menuSettings',
            navItem.menuSettings
          );
        }

        this._bottomSheetRef
          .afterOpened()
          .pipe(takeUntilDestroyed(this._destroyRef))
          .subscribe(() => {
            this.bottomSheetOpen.set(true);
            // Run change detection manually since this is not marked as dirty.
            this._cdRef.detectChanges();
          });

        this._bottomSheetRef
          .afterDismissed()
          .pipe(takeUntilDestroyed(this._destroyRef))
          .subscribe((data) => {
            // Note: this `data` is the value provided when the bottom-sheet is dismissed.
            // libs/esp/misc/feature-bottom-sheet-menu/src/lib/bottom-sheet-menu.component.ts#90

            //Note:  If no data provided, close the open bottom-sheet panel
            if (!data) {
              this.bottomSheetOpen.set(false);
              // Run change detection manually since this is not marked as dirty.
              this._cdRef.detectChanges();
              this._document.body.classList.remove('bottom-sheet-open');
              return;
            }

            //Note: In the mobile navigation, when the user clicks the back arrow button, we should close the existing menu and open the main one
            if (data === 'more') {
              const parent = this.navItemsMobile().find((it) => it.id === data);
              this._dismissAndOpen(parent!);
              return;
            }

            const childrenItem: INavigationItem | undefined = (
              getChildrenItems(navItem) || []
            ).find((it) => it.id === data);

            if (!childrenItem) {
              ngDevMode &&
                console.error(`The requested menu item doesn't exist.`);
              return;
            }

            if (childrenItem.actionOnClick) {
              this._ngZone.runOutsideAngular(() => {
                this._actionExecutor(childrenItem.actionOnClick!);
              });
            }

            this._dismissAndOpen(childrenItem);
          });
      });
  }

  onResize(entry: ResizeObserverEntry) {
    if (!entry.contentRect.width) {
      this._ngZone.run(() => this._bottomSheet.dismiss());
    }
  }

  closeBottomSheet() {
    if (this.bottomSheetOpen()) {
      this._bottomSheetRef?.dismiss();
      this.bottomSheetOpen.set(false);
      // Run change detection manually since this is not marked as dirty.
      this._cdRef.detectChanges();
    }
  }

  private _closeBottomSheetOnNavigation() {
    this._router.events
      .pipe(
        filter(
          (event): event is NavigationStart => event instanceof NavigationStart
        ),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => {
        this.closeBottomSheet();
      });
  }

  /**
   * Closes the current bottom sheet and opens the new one.
   */
  private _dismissAndOpen(navItem: INavigationItem) {
    this.closeBottomSheet();
    this.openBottomSheet(navItem);
  }
}
