import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
  type ApplicationConfig,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { provideThemes } from '@cosmos/data-access-themes';
import { OptInPreloadStrategy } from '@cosmos/router';
import { CosTheme } from '@cosmos/types-common';
import { provideI18nDateFormats } from '@cosmos/util-i18n-dates';

import { environment } from '../environments/environment';

import { routes } from './app-routes';
import { makeCoreEnvironmentProviders } from './core';

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),

    // `BrowserAnimationsModule`.
    provideAnimations(),

    // `HttpClientModule`.
    provideHttpClient(withInterceptorsFromDi()),

    // `RouterModule`.
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withPreloading(OptInPreloadStrategy)
    ),

    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.serviceWorkerEnabled,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    importProvidersFrom(
      NgxSkeletonLoaderModule.forRoot({
        animation: 'progress-dark',
        loadingText: 'loading...',
        theme: {
          extendsFromRoot: true,
          background: 'var(--base-200)',
        },
      })
    ),

    makeCoreEnvironmentProviders(),

    provideI18nDateFormats(),

    provideThemes({
      defaultTheme: CosTheme.CustomerPortal,
      appId: 'customer-portal',
    }),
  ],
};
