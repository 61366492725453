// THIS FILE IS BEING UPDATED USING GENERATORS. DO NOT EDIT MANUALLY.

export enum CosTheme {
  ESP = 'esp',
  Supplier = 'supplier',
  Vernon = 'vernon',
  ESPDark = 'esp-dark',
  CustomerPortal = 'customer-portal',
  AIACore = 'aia-core',
  PPM = 'ppm',
  Fullypromoted = 'fullypromoted',
  Halo = 'halo',
}
