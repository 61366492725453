<div class="flex justify-between w-full">
  <!-- left header -->
  <div class="flex items-center">
    <ngx-skeleton-loader
      class="flex"
      [theme]="{
        height: '50px',
        width: '50px',
        margin: '0 12px 0 0',
        'aspect-ratio': '1/1',
      }"
    />

    <div class="left-header-divider"></div>

    <div>
      <ngx-skeleton-loader
        [theme]="{ height: '14px', width: '280px', marginTop: '10px' }"
      />
    </div>
  </div>

  <!-- right header -->
  <div class="flex justify-between gap-5" id="menu-icons">
    <div class="flex flex-col pt-2.5">
      <ngx-skeleton-loader
        class="self-center"
        [theme]="{
          height: '30px',
          width: '30px',
          'margin-bottom': '0',
        }"
      />
      <ngx-skeleton-loader
        [theme]="{
          height: '12px',
          width: '60px',
          'margin-bottom': '0',
        }"
      />
    </div>
    <div class="flex flex-col pt-2.5">
      <ngx-skeleton-loader
        class="self-center"
        [theme]="{
          height: '30px',
          width: '30px',
          'margin-bottom': '0',
        }"
      />
      <ngx-skeleton-loader
        [theme]="{
          height: '12px',
          width: '60px',
          'margin-bottom': '0',
        }"
      />
    </div>
  </div>
</div>
