import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseAsiAuthService } from '@asi/auth/data-access-auth';
import { MarsAuthService } from '@customer-portal/auth/data-access-auth';
import { SednaAuthService } from '@customer-portal/collections/data-access-auth';

@Injectable({
  providedIn: 'root',
})
export class CustomerPortalAuthService extends BaseAsiAuthService {
  private _sednaAuthService = inject(SednaAuthService);
  private _marsAuthService = inject(MarsAuthService);
  private _location = inject(Location);

  getToken(): Observable<string> {
    return this._authService.getToken();
  }

  refreshToken(): Observable<string | null> {
    return this._authService.refreshToken();
  }

  login(body: unknown) {
    return this._authService.login(body);
  }

  private get _authService(): BaseAsiAuthService {
    const currentUrl = this._location.path();
    if (
      currentUrl.startsWith('/collections') ||
      currentUrl.startsWith('/products')
    ) {
      return this._sednaAuthService;
    } else {
      return this._marsAuthService;
    }
  }
}
