import { filter, map, share, type Observable } from 'rxjs';

import { unpatchedFromEvent } from '@cosmos/zone-less';

import type { DataBusConfig, DataBusMessage } from './types';

/**
 * @usage 
 * ```typescript
 * private readonly _dataBus = new IframeDataBus({
 *      currentWindow: inject(WINDOW),
 * });
 * ...
 * this._dataBus.onMessage$
 * .pipe(takeUntil(..))
 * .subscribe((message) => console.log('only data bus messages here');
 * ...
 * const message: DataBusMessage = { .. };
 * this._dataBus.emit(message);
```
 */
export abstract class DataBus<T extends DataBusMessage = DataBusMessage> {
  /**
   * Returns an observable of message events for the provided `config.currentWindow`.
   * Uses zoneless API.
   */
  readonly onMessage$ = this._getOnMessage$();

  constructor(protected config: DataBusConfig) {
    if (typeof config.origin !== 'string') {
      config.origin = '*';
    }
  }

  /**
   * Dispatches the provided message to the given target
   * @param message message to be sent
   */
  dispatch(message: T): void {
    if (global_isServer) {
      return;
    }

    this._getTarget()?.postMessage(message, this.config.origin!);
  }

  protected _getOnMessage$(): Observable<DataBusMessage> {
    return unpatchedFromEvent<MessageEvent>(
      this.config.currentWindow,
      'message'
    ).pipe(
      filter((message) => message.source === this._getTarget()),
      map((message) => message.data),
      filter((data): data is DataBusMessage => !!data.type),
      share()
    );
  }

  /**
   * Method that should return a target to emit to.
   * May return null if target is not resolved (e.g. iframe is detached from the host window)
   */
  protected abstract _getTarget(): Window | null;
}
