<!-- Desktop Menu -->
<nav class="cos-global-nav-desktop" [attr.aria-label]="ariaLabel()">
  <ul class="cos-global-nav-menu" [ngClass]="theme()" #menuRef>
    @for (
      navItem of navItemsDesktop()
        | featureFlagsFilter
        | roleFilter
        | capabilityFilter;
      track navItem
    ) {
      @if (!navItem.hidden) {
        <li
          class="cos-global-nav-item"
          [cosTooltip]="
            navItem.disable ? (navItem.disabledTooltip | cosTranslate) : ''
          "
        >
          @if (navItem.url && !navItem.externalUrl && !navItem.function) {
            <a
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              [ngClass]="navItem.classes || []"
              [routerLink]="navItem.url"
              [routerLinkActive]="['active', 'mat-accent-bg']"
              [routerLinkActiveOptions]="{ exact: !!navItem.exactMatch }"
              [queryParamsHandling]="navItem.queryParamsHandling"
              [target]="navItem.openInNewTab ? '_blank' : '_self'"
              [disabled]="navItem.disable"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </a>
          }
          @if (navItem.externalUrl && navItem.url) {
            <a
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              [href]="navItem.url"
              [target]="navItem.openInNewTab ? '_blank' : '_self'"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </a>
          }
          @if (navItem.menuComponent) {
            <button
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              [cosMenuTriggerFactory]="navItem.menuComponent"
              [cosMenuSettings]="navItem.menuSettings"
              [cosActionDelagateOnClick]="navItem.actionOnClick"
              (menuClosed)="(true)"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </button>
          }
          @if (
            navItem.actionOnClick &&
            !navItem.url &&
            !navItem.externalUrl &&
            !navItem.menuComponent &&
            !navItem.function
          ) {
            <button
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              [cosActionDelagateOnClick]="navItem.actionOnClick"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </button>
          }
        </li>
      }
    }
  </ul>
</nav>
<!-- /Desktop Menu -->

<!-- Mobile Menu -->
<nav
  class="cos-global-nav-mobile"
  [attr.aria-label]="ariaLabel()"
  (cosOnResize)="onResize($event)"
>
  <ul class="cos-global-nav-menu" #menuRef>
    @for (
      navItem of navItemsMobile()
        | featureFlagsFilter
        | roleFilter
        | capabilityFilter;
      track navItem
    ) {
      @if (!navItem.hidden) {
        <li
          class="cos-global-nav-item"
          [class.--expanded]="
            bottomSheetOpen() && navItem.type === 'collapsable'
          "
        >
          @if (
            navItem.type !== 'collapsable' &&
            navItem.url &&
            !navItem.externalUrl
          ) {
            <a
              cos-button
              size="sm"
              
              [attr.id]="navItem.id || null"
              [disabled]="navItem.disable"
              [routerLink]="navItem.url"
              [routerLinkActive]="['active', 'mat-accent-bg']"
              [routerLinkActiveOptions]="{ exact: !!navItem.exactMatch }"
              [target]="navItem.openInNewTab ? '_blank' : '_self'"
              [ngClass]="navItem.classes || []"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </a>
          }
          @if (
            navItem.type !== 'collapsable' && navItem.url && navItem.externalUrl
          ) {
            <a
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              [href]="navItem.url"
              [target]="navItem.openInNewTab ? '_blank' : '_self'"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </a>
          }
          @if (navItem.menuComponent) {
            <button
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              [cosMenuTriggerFactory]="navItem.menuComponent"
              [cosMenuSettings]="navItem.menuSettings"
              (click)="closeBottomSheet()"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </button>
          }
          @if (navItem.type === 'collapsable') {
            <button
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              (click)="openBottomSheet(navItem)"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </button>
          }

          @if (
            navItem.actionOnClick &&
            !navItem.url &&
            !navItem.externalUrl &&
            !navItem.menuComponent &&
            !navItem.function
          ) {
            <button
              cos-button
              size="sm"
              [attr.id]="navItem.id || null"
              [cosActionDelagateOnClick]="navItem.actionOnClick"
            >
              <ng-template
                [ngTemplateOutlet]="navIconTmpl"
                [ngTemplateOutletContext]="{ $implicit: navItem }"
              />
              @if (!navItem.hideTitle) {
                <span class="cos-global-nav-text">{{
                  navItem.title | cosTranslate
                }}</span>
              }
            </button>
          }
        </li>
      }
    }
  </ul>
</nav>
<!-- /Mobile Menu -->

<ng-template #navIconTmpl let-navItem>
  <i
    class="cos-global-nav-icon"
    [ngClass]="
      navItem.type === 'collapsable' && bottomSheetOpen()
        ? 'fa fa-times'
        : navItem.icon
    "
  >
    @if (navItem.badge?.title) {
      <span class="cos-counter">
        {{ navItem.badge.title }}
      </span>
    }
  </i>
</ng-template>
