<div id="main">
  <div
    id="container-1"
    class="customer-portal-layout-container-wrapper customer-portal-layout-container"
  >
    <div
      id="container-2"
      class="customer-portal-layout-container-outer customer-portal-layout-container"
    >
      <div
        id="container-3"
        class="customer-portal-layout-container-inner customer-portal-layout-container"
      >
        <div id="content" class="customer-portal-layout-inner-scroll">
          <router-outlet />
        </div>
      </div>
    </div>
  </div>
</div>
